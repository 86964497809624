import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

const faqs = [
  {
    question: "What's your Return Policy?",
    answer:
      "MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor.",
  },
  {
    question: "What's your Return Policy?",
    answer:
      "MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor.",
  },
  {
    question: "What's your Return Policy?",
    answer:
      "MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor.",
  },
  {
    question: "What's your Return Policy?",
    answer:
      "MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor MSB Motor.",
  },
  // More questions...
];

export default function ReturnPolicy() {
  useTitle("Return Policy" + pageTitle);

  return (
    <div className="bg-light-50">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-24 lg:px-8 lg:py-24">
        <div className="mx-auto max-w-6xl divide-y divide-dark-900/10">
          <h2 className="text-base font-light uppercase leading-10 tracking-tight text-dark-900">
            Return Policy
          </h2>
          <ul className="max-w-md list-disc space-y-2 pl-4 pt-2 text-xs font-light">
            <li>
              You can exchange the size and color only. Exchanges must be made
              maximum 7 days after you receive the package and as long as we
              still have the stocks for, except for pre-order items.
            </li>
            <li>
              Items must be in the same condition they were purchased in; labels
              are still attached and be in perfect condition (unworn, unaltered,
              and unwashed).
            </li>
            <li>Sale items are not exchangeable/returnable.</li>
            <li>Video proof must be provided for complaints.</li>
            <li>Returns and exchanges can only occur once.</li>
            <li>
              We do not issue any refund by cash or transfer, except if we lose
              your order or if the items are broken and we don’t have the stock
              to exchange.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
