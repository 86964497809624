const initState = {
  product_list: [],
  category_list: [],
  detail: null,
  cart_list: [],
  user_order: [],
  loading: false,
  detail_loading: false,
  cart_loading: false,
  delete_loading: false,
  transaction_loading: false,
  error: null,
  carousel: [],
};

const productsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      console.log(`set ${action.name}: ${action.payload}`);
      return {
        ...state,
        [`${action.name}`]: action.payload,
      };
    case "SET_LOADING_PRODUCT":
      console.log("set loading product");
      return {
        ...state,
        loading: true,
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        product_list: action.payload,
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        product_list: action.payload,
        loading: false,
      };
    case "GET_PRODUCTS_ERROR":
      return {
        ...state,
        product_list: [],
        error: action.error,
        loading: false,
      };
    case "GET_CAROUSEL_SUCCESS":
      return {
        ...state,
        carousel: action.payload,
        loading: false,
      };
    case "GET_CAROUSEL_ERROR":
      return {
        ...state,
        carousel: [],
        error: action.error,
        loading: false,
      };
    case "GET_CATEGORIES_SUCCESS":
      return {
        ...state,
        category_list: action.payload,
        loading: false,
      };
    case "GET_CATEGORIES_ERROR":
      return {
        ...state,
        category_list: [],
        error: action.error,
        loading: false,
      };
    case "GET_PRODUCT_INFO_SUCCESS":
      return {
        ...state,
        detail: action.payload,
        detail_loading: false,
      };
    case "GET_PRODUCT_INFO_ERROR":
      return {
        ...state,
        detail: null,
        error: action.error,
        detail_loading: false,
      };
    case "UPDATE_CART_SUCCESS":
      return {
        ...state,
        cart_list: action.payload,
        cart_loading: false,
      };
    case "UPDATE_CART_ERROR":
      return {
        ...state,
        error: action.error,
        cart_loading: false,
      };
    case "GET_ORDERS_SUCCESS":
      return {
        ...state,
        user_order: action.payload,
        loading: false,
      };
    case "GET_ORDERS_ERROR":
      return {
        ...state,
        user_order: [],
        error: action.error,
        loading: false,
      };
    case "GET_CART_LIST_SUCCESS":
      return {
        ...state,
        cart_list: action.payload,
        cart_loading: false,
      };
    case "GET_CART_LIST_ERROR":
      return {
        ...state,
        cart_list: null,
        error: action.error,
        cart_loading: false,
      };
    case "CREATE_TRANSACTION_ERROR":
      return {
        ...state,
        error: action.error,
        transaction_loading: false,
      };
    default:
      return state;
  }
};

export default productsReducer;
