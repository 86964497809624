import axios from "axios";
import { api } from "../../configs/api";

export const getCategory = () => {
  return async (dispatch) => {
    try {
      let url = `${api}/api/v1/categories/?is_active=true`;
      await dispatch({ type: "SET_LOADING", name: "loading", payload: true });
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        console.log("category: ", response.data);
        dispatch({ type: "GET_CATEGORIES_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "GET_CATEGORIES_ERROR", error });
      console.log("action getProduct error: ", error);
    }
  };
};

export const getProduct = (category, search) => {
  return async (dispatch) => {
    try {
      let url = `${api}/api/v1/products?isActive=true`;
      await dispatch({ type: "SET_LOADING", name: "loading", payload: true });

      let queries = [];
      if (category) {
        queries.push(`category=${category}`);
      }
      if (search) {
        queries.push(`search=${search}`);
      }

      let temp = "";
      for (let query of queries) {
        temp += `&${query}`;
      }

      const { data: response } = await axios({
        method: "GET",
        url: `${url}${temp}`,
      });

      if (response && response.status == "success") {
        dispatch({ type: "GET_PRODUCTS_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "GET_PRODUCTS_ERROR", error });
      console.log("action getProduct error: ", error);
    }
  };
};

export const getCarousel = () => {
  return async (dispatch) => {
    try {
      let url = `${api}/api/v1/carousel?is_active=true`;
      await dispatch({ type: "SET_LOADING", name: "loading", payload: true });

      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        dispatch({ type: "GET_CAROUSEL_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "GET_CAROUSEL_ERROR", error });
      console.log("action getCarousel error: ", error);
    }
  };
};

const checkGross = (product, qty) => {
  if (
    product?.gross_qty <= qty &&
    product?.gross_qty > 0 &&
    product?.gross_price > 0
  ) {
    return product?.gross_price;
  }

  return product?.min_price;
};

export const updateCart = (product, variant, sizeVariant, qty) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "cart_loading",
        payload: true,
      });
      const token = localStorage.getItem("token");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/orders/add-cart`,
        data: {
          product_id: product.id,
          color_variant_id: variant && variant.id,
          size_variant_id: sizeVariant && sizeVariant.id,
          product_name: product.name,
          product_desc: product.description,
          product_image: product && product?.DetailImage?.path,
          variant: variant && variant.name,
          qty,
          price: sizeVariant ? sizeVariant.price : checkGross(product, qty),
          has_discount: sizeVariant
            ? Number(sizeVariant.discount) > 0
            : Number(product.discount) > 0,
          total_price: sizeVariant
            ? sizeVariant.price * qty
            : checkGross(product, qty) * qty,
          discount: sizeVariant ? sizeVariant.discount : product.discount,
          transaction_id: null,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        dispatch(getUserCart());
      }
    } catch (error) {
      dispatch({ type: "UPDATE_CART_ERROR", error });
      alert(error?.response?.data?.message);
    }
  };
};

export const updateProductQty = (id, qty, product) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "cart_loading",
        payload: true,
      });
      const token = localStorage.getItem("token");
      const { data: response } = await axios({
        method: "PUT",
        url: `${api}/api/v1/orders/update-cart/${id}`,
        data: {
          qty,
          color_variant_id: product.color_variant_id,
          size_variant_id: product.size_variant_id,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        dispatch(getUserCart());
      }
    } catch (error) {
      dispatch({ type: "UPDATE_CART_ERROR", error });
      alert(error?.response?.data?.message);
      console.log("action updateCart error: ", error);
    }
  };
};

export const getUserCart = () => {
  const token = localStorage.getItem("token");
  console.log("token for user_cart", token);
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "cart_loading",
        payload: true,
      });
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/orders/user-cart`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        dispatch({ type: "GET_CART_LIST_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "GET_CART_LIST_ERROR", error });
      console.log("action getProduct error: ", error);
    }
  };
};

const convertColorSizeObject = (variant) => {
  const colors = {};

  variant.forEach((item) => {
    const colorId = item.color_variant_id;
    const colorName = item.ColorVariant.name;
    // const images = item.ColorImages;

    // console.log("var item is: ", item);
    if (!colors[colorId]) {
      colors[colorId] = {
        id: colorId,
        name: colorName,
        sizes: [],
        // images: images,
      };
    }

    const size = {
      id: item.size_variant_id,
      name: item.SizeVariant.name,
      price: item.price,
      stock: item.stock,
      discount: item.discount,
      index: Number(item.SizeVariant.indexing),
    };

    colors[colorId].sizes.push(size);
  });

  const uniqueColors = Object.values(colors);
  return uniqueColors;
};

export const getProductDetail = (id) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "detail_loading",
        payload: true,
      });
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/products/${id}`,
      });

      if (response && response.status == "success") {
        let product = response.data;
        let variant = product.ProductColorSizes;
        product["ProductColorSizes"] = convertColorSizeObject(variant);
        dispatch({ type: "GET_PRODUCT_INFO_SUCCESS", payload: product });
      }
    } catch (error) {
      // dispatch({ type: 'GET_PRODUCT_INFO_ERROR', error })
      console.log("action getProduct error: ", error);
    }
  };
};

export const deleteProductCart = (id) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "delete_loading",
        payload: true,
      });
      const token = localStorage.getItem("token");
      const { data: response } = await axios({
        method: "DELETE",
        url: `${api}/api/v1/orders/${id}`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        await dispatch(getUserCart());
        await dispatch({
          type: "SET_LOADING",
          name: "delete_loading",
          payload: false,
        });
      }
    } catch (error) {
      dispatch({ type: "UPDATE_CART_ERROR", error });
      console.log("action updateCart error: ", error);
    }
  };
};

export const userOrders = (transaction, nav) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: "SET_LOADING", name: "loading", payload: true });
      const token = localStorage.getItem("token");
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/transactions/user`,
        data: transaction,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        dispatch({ type: "GET_ORDERS_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "GET_ORDERS_ERROR", error });
    }
  };
};

export const createTransaction = (transaction, nav) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_LOADING",
        name: "transaction_loading",
        payload: true,
      });
      const token = localStorage.getItem("token");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/transactions/create-transaction`,
        data: transaction,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response && response.status == "success") {
        await dispatch({
          type: "SET_LOADING",
          name: "transaction_loading",
          payload: false,
        });
        await dispatch(getUserCart());
        alert(
          "Terimakasih telah melakukan transaksi di MSB Motor. Silahkan cek email anda untuk detail pembayaran."
        );
        // window.open(response.data.payment_url, "_self");
        // await window.open(response.data.invoice_url);
        await nav("/order-received");
        await window.location.reload();
      }
    } catch (error) {
      dispatch({ type: "CREATE_TRANSACTION_ERROR", error });
    }
  };
};
