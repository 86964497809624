import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { authLogin } from "../../redux/actions/authAction";
import { useTitle } from "../../helper/functions";
import { pageTitle } from "../../helper/globals";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { api } from "../../configs/api";

export default function VerificationEmailPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    token: "",
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  let from = location.state?.from;

  useTitle("Reset Password" + pageTitle);

  const verifyEmail = async () => {
    try {
      console.log("run verify email");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/auth/verify`,
        data: state,
      });

      if (response && response.status == "success") {
        console.log("resposne: ", response);
        alert(response.message);
        setState({
          token: "",
        });
        navigate("/auth/");
      }
    } catch (error) {
      console.log("error", error);
      alert(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    let token = location?.search;
    if (token) {
      let newtoken = token.substring(1).split("=")[1];
      console.log("params reset: ", newtoken);
      setState({
        ...state,
        token: newtoken,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-dark-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-col justify-center px-8 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-left text-sm font-light uppercase tracking-tight text-dark-900">
            Verifikasi Alamat Email
          </h2>
          <h3 className="mt-2 text-left text-xs font-light tracking-tight text-dark-900">
            Tekan tombol Verifikasi Email untuk dapat login dan mulai berbelanja.
          </h3>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="">
            <div className="space-y-4">
              <div>
                <button
                  className="flex w-full justify-center border border-transparent bg-primary-600 px-4 py-2 text-xs font-light uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => verifyEmail()}
                >
                  Verifikasi Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
