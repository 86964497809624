import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../components/navbar";

import LoginPage from "./login";
import RegisterPage from "./register";
import ResetPage from "./reset";
import ChangePasswordPage from "./changePassword";
import VerificationEmailPage from "./verifyEmail";

export default function AuthPage(props) {
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route index element={<LoginPage />} />
        {/* <Route path="login" element={<LoginPage />} /> */}
        <Route path="register" element={<RegisterPage />} />
        <Route path="forget" element={<ResetPage />} />
        <Route path="reset" element={<ChangePasswordPage />} />
        <Route path="verify" element={<VerificationEmailPage />} />
      </Routes>
    </>
  );
}
