import { Helmet } from "react-helmet-async";
import MeasurementGuideContent from "../components/info/measurement-guide-content";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";

export default function MeasurementGuide() {
  useTitle("Measurement Guide" + pageTitle);

  return (
    <div className="bg-light-50 px-6 py-32 lg:px-8">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <div className="mx-auto max-w-3xl text-base leading-7 text-dark-700">
        <h1 className="mt-2 text-base font-light uppercase tracking-tight text-dark-900">
          Measurement Guide
        </h1>
        <div className="mt-4 max-w-2xl text-xs font-light leading-relaxed">
          <MeasurementGuideContent />
        </div>
      </div>
    </div>
  );
}
