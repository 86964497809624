import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import productsReducer from "./reducers/productsReducer";
import authReducers from "./reducers/actionReducer";

import thunk from "redux-thunk";
const store = createStore(
  combineReducers({
    auth: authReducers,
    products: productsReducer,
  }),
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
