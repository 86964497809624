import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../configs/api";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";
import { autoLogin } from "../redux/actions/authAction";
import { checkNull } from "../helper/functions";

export default function ProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_info = useSelector((state) => state.auth.user_info);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    province: "",
    city: "",
    address: "",
    address_info: "",
    postal_code: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  useTitle("My Profile" + pageTitle);

  const location = useLocation();
  let from = location.state?.from;

  const update = async () => {
    try {
      const { data: response } = await axios({
        method: "PUT",
        url: `${api}/api/v1/user-info/${user_info.id}`,
        data: state,
      });

      if (response && response.status == "success") {
        alert("Update Successful!");
        await dispatch(autoLogin());
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getProfile = async () => {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/user-info/${user_info?.id}`,
      });

      if (response && response.status == "success") {
        console.log("resposne user profile: ", response);
        setState({
          ...state,
          first_name: checkNull(response?.data?.first_name),
          last_name: checkNull(response?.data?.last_name),
          email: checkNull(response?.data?.email),
          country: checkNull(response?.data?.country),
          province: checkNull(response?.data?.province),
          city: checkNull(response?.data?.city),
          address: checkNull(response?.data?.address),
          address_info: checkNull(response?.data?.address_info),
          postal_code: checkNull(response?.data?.postal_code),
          phone: checkNull(response?.data?.phone),
        });
      }
    } catch (error) {
      console.log("error get profile", error);
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/country`,
      });

      if (response && response.status == "success") {
        setCountries(response.data);
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const logout = async () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    navigate("/auth/");
  };

  const getProvinces = async () => {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/province`,
      });

      if (response && response.status == "success") {
        setProvinces(response.data);
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const getCities = async () => {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/city?province=${state.province}`,
      });

      if (response && response.status == "success") {
        await setCities(response.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getData = async () => {
    await setLoading(true);
    await getCountries();
    await getProfile();
    await getProvinces();
    await setLoading(false);
  };

  const citySetup = async () => {
    await setCities([]);
    await getCities();
  };

  useEffect(() => {
    if (user_info?.user_id) {
      getData();
    }
  }, [user_info && user_info.user_id]);

  useEffect(() => {
    console.log("perubahan provinsi");
    if (state?.province) {
      citySetup();
    }
  }, [state.province, state.country]);

  return (
    <div className="flex min-h-full flex-col justify-center px-8 py-4 sm:px-6 lg:px-8">
      <Helmet>
        <meta name="description" content="MSB Motor description." />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      {loading && <div className="bg-gray z-40 h-full w-full">Loading...</div>}
      <div className="mt-2 flex items-center justify-between sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-left text-sm font-normal uppercase tracking-wider text-dark-900">
          Profil
        </h2>
        <a
          href="/my-orders"
          className="text-base font-semibold text-primary-600 hover:text-primary-500"
        >
          Histori Transaksi
        </a>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="first_name"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Nama Depan
              </label>
              <div className="mt-1">
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  required
                  value={state.first_name}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      first_name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Nama Belakang
              </label>
              <div className="mt-1">
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  required
                  value={state.last_name}
                  placeholder="+6281888777665"
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Alamat Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={state.email}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Nomor HP
              </label>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  autoComplete="phone"
                  required
                  value={state.phone}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="address"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Alamat
              </label>
              <div className="mt-1">
                <input
                  id="address"
                  name="address"
                  type="address"
                  autoComplete="address"
                  required
                  value={state.address}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      address: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="address-2"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Alamat (detail gedung / apartemen)
              </label>
              <div className="mt-1">
                <input
                  id="address-2"
                  name="address-2"
                  type="address"
                  autoComplete="address-2"
                  required
                  value={state.address_info}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      address_info: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            {/* <div>
              <label
                htmlFor="address-2"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Country (select if outside Indonesia)
              </label>
              <select
                value={state.country}
                className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                onChange={(e) => {
                  console.log("on change: ", e.target.value);
                  setState({
                    ...state,
                    country: e.target.value,
                    province: "",
                    city: "",
                  });
                }}
              >
                <option value={""}>Choose Country</option>
                {countries?.length > 0 &&
                  countries?.map((list, index) => (
                    <option key={index} value={list.country_id}>
                      {list.country_name}
                    </option>
                  ))}
              </select>
            </div> */}

            {state.country == "" ? (
              <>
                <div>
                  <label
                    htmlFor="province"
                    className="block text-xs font-light uppercase text-dark-700"
                  >
                    Provinsi
                  </label>
                  <select
                    name="province"
                    value={state.province}
                    className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      console.log("on change: ", e.target.value);
                      setState({
                        ...state,
                        province: e.target.value,
                        city: "",
                      });
                    }}
                  >
                    <option value={null}>Pilih Provinsi</option>
                    {provinces?.length > 0 &&
                      provinces?.map((list, index) => (
                        <option key={index} value={list.province_id}>
                          {list.province}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-light uppercase text-dark-700"
                  >
                    Kota
                  </label>
                  <select
                    name="city"
                    value={state.city}
                    className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      console.log("on change: ", e.target.value);
                      setState({
                        ...state,
                        city: e.target.value,
                      });
                    }}
                  >
                    <option value={null}>Pilih Kota</option>
                    {cities?.length > 0 &&
                      cities?.map((list, index) => (
                        <option key={index} value={list.city_id}>
                          {list.city_name}
                        </option>
                      ))}
                  </select>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label
                    htmlFor="province"
                    className="block text-xs font-light uppercase text-dark-700"
                  >
                    Province
                  </label>
                  <div className="mt-1">
                    <input
                      id="province"
                      name="province"
                      type="text"
                      autoComplete="province"
                      required
                      className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      value={state.province}
                      onChange={(e) => {
                        setState({
                          ...state,
                          province: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-light uppercase text-dark-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      id="city"
                      name="city"
                      type="text"
                      autoComplete="city"
                      required
                      className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      value={state.city}
                      onChange={(e) => {
                        setState({
                          ...state,
                          city: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {/* <div>
              <label
                htmlFor="province"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Province
              </label>
              <div className="mt-1">
                <input
                  id="province"
                  name="province"
                  type="province"
                  autoComplete="province"
                  required
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      province: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-xs font-light uppercase text-dark-700"
              >
                City
              </label>
              <div className="mt-1">
                <input
                  id="city"
                  name="city"
                  type="city"
                  autoComplete="city"
                  required
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      city: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="subdistrict"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Sub-District
              </label>
              <div className="mt-1">
                <input
                  id="subdistrict"
                  name="subdistrict"
                  type="subdistrict"
                  autoComplete="subdistrict"
                  required
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      subdistrict: e.target.value,
                    });
                  }}
                />
              </div>
            </div> */}

            <div>
              <label
                htmlFor="postcode"
                className="block text-xs font-light uppercase text-dark-700"
              >
                Kode Pos
              </label>
              <div className="mt-1">
                <input
                  id="postcode"
                  name="postcode"
                  type="postcode"
                  autoComplete="postcode"
                  required
                  value={state.postal_code}
                  className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    setState({
                      ...state,
                      postal_code: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="space-y-4 pt-4">
              <button
                className="flex w-full justify-center border border-transparent bg-primary-600 px-4 py-2 text-sm font-normal uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => update()}
              >
                Simpan Profil
              </button>
              <button
                className="flex w-full justify-center border border-dark-900 px-4 py-2 text-sm font-normal uppercase text-dark-900 shadow-sm hover:bg-dark-900 hover:text-dark-100 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2"
                onClick={() => logout()}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
