import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import AuthFashion from "./Auth";
import AboutUs from "./about";
import ContactUs from "./contact";
import Detail from "./detail";
import FAQ from "./faq";
import OrdersPage from "./order";
import PaymentSuccess from "./payment-success";
import Products from "./products";
import ProfilePage from "./profile";
import ReturnPolicy from "./return-policy";
import TnC from "./tnc";
import PrivacyPolicy from "./privacy-policy";
import MeasurementGuide from "./measurement-guide";
import NotFound from "./notFoundError";
import OrderReceived from "./order-received";

export default function Fashion(props) {
  return (
    <div className="font-regular">
      {/* <Navbar /> */}
      <Routes>
        <Route index element={<Products />} />
        <Route path="collections/" element={<Products />} />
        <Route path="collections/detail/:id" element={<Detail />} />
        <Route path="products/" element={<Products />} />
        <Route path="products/detail/:id" element={<Detail />} />
        <Route path="auth/" element={<AuthFashion />} />
        <Route path="my-orders/" element={<OrdersPage />} />
        <Route path="payment-success/" element={<PaymentSuccess />} />
        <Route path="order-received/" element={<OrderReceived />} />
        <Route path="my-profile/" element={<ProfilePage />} />
        <Route path="faq/" element={<FAQ />} />
        <Route path="return-policy/" element={<ReturnPolicy />} />
        <Route path="contact/" element={<ContactUs />} />
        <Route path="about/" element={<AboutUs />} />
        <Route path="tnc/" element={<TnC />} />
        <Route path="privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="measurement-guide/" element={<MeasurementGuide />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}
