import { RadioGroup } from "@headlessui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../configs/api";
import {
  formatCurrency,
  grandTotal,
  useTitle,
  totalWeight,
  checkStock,
  productPrice,
  productPriceNum,
} from "../helper/functions";
import { createTransaction } from "../redux/actions/productsAction";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.cart_list);
  const isLoading = useSelector((state) => state.products.transaction_loading);
  const user_info = useSelector((state) => state.auth.user_info);
  const [info, setInfo] = useState({
    email: "",
    first_name: "",
    last_name: "",
    address: "",
    address_info: "",
    city: "",
    country: "",
    province: "",
    postal_code: "",
    phone: "",
  });
  const [shippingCost, setShippingCost] = useState(0);

  const [open, setOpen] = useState(false);
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(null);
  const [ongkir, setOngkir] = useState([]);
  const [delivery, setDelivery] = useState("");
  const [preOrderNotice, setPreOrderNotice] = useState(false);
  const [outStockNotice, setOutStockNotice] = useState(false);

  const checkoutOrder = async () => {
    if (shippingCost === 0) {
      alert("Please select shipping method.");
    } else {
      const transaction = {
        ...info,
        delivery_method: delivery,
        delivery_fee: shippingCost,
        paymentMethods: "",
        grand_total: grandTotal(products, shippingCost),
        products,
      };

      let preOrder = 0;
      let outStock = 0;

      for (let i in products) {
        if (checkStock(products[i]) == "(Pre Order)") preOrder += 1;
        if (checkStock(products[i]) == "(Out of Stock)") outStock += 1;
      }

      if (outStock) {
        console.log("terdapat product yang out stock");
        alert(
          "Maaf, ada produk di dalam keranjang Anda yang sedang habis. Harap hapus produk yang habis."
        );
        navigate("/cart");
      } else {
        if (preOrder) {
          if (
            window.confirm(`${
              preOrder
                ? "Terdapat produk Pre Order dalam keranjang Anda, produk PO akan dikirimkan dalam 30 hari. Apakah Anda mau melanjutkan?"
                : ""
            }
        `)
          ) {
            console.log("run pre order");
            transaction["is_po"] = true;
            dispatch(createTransaction(transaction, navigate));
          } else {
            navigate("/");
            window.location.reload();
          }
        } else {
          dispatch(createTransaction(transaction, navigate));
        }
      }
    }
  };

  async function getDeliveryMethods() {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/deliveries`,
      });

      if (response && response.status == "success") {
        console.log("delivery methods: ", response.data);
        await setDeliveryMethods(response.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const getOngkir = async () => {
    try {
      console.log("getOngkir run");
      let destination = user_info?.city;
      let courier = "jne";
      let ro_url = "domestic";
      if (user_info?.country != "" && user_info?.country) {
        destination = user_info.country;
        ro_url = "international";
      }

      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/raja-ongkir/cost/${ro_url}`,
        data: {
          destination,
          weight: totalWeight(products),
          courier,
        },
      });

      if (response && response.status == "success") {
        console.log("response ongkir: ", response);
        let packageList = response.data[0].costs;
        if (
          totalWeight(products) <=
            Number(process.env.REACT_APP_INSTANT_KURIR_CONDITION) &&
          destination == process.env.REACT_APP_SELLER_ORIGIN
        ) {
          packageList.push({
            cost: [
              {
                value: process.env.REACT_APP_INSTANT_ONGKIR,
                etd: "<1",
                note: "",
              },
            ],
            description: "Kurir Instan",
            service: "GRAB/GOJEK",
          });
        }
        if (
          grandTotal(products) >=
          Number(process.env.REACT_APP_FLAT_ONGKIR_CONDITION)
        ) {
          packageList.push({
            cost: [
              {
                value: process.env.REACT_APP_FLAT_ONGKIR,
                etd: "3-5",
                note: "",
              },
            ],
            description: "Spesial Ongkir MSB Motor",
            service: "Spesial Ongkir MSB Motor",
          });
        }
        setOngkir(packageList);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    setInfo({
      email: user_info?.email,
      first_name: user_info?.first_name,
      last_name: user_info?.last_name,
      address: user_info?.address,
      address_info: user_info?.address_info,
      city: user_info?.city,
      country: user_info?.country,
      province: user_info?.province,
      postal_code: user_info?.postal_code,
      phone: user_info?.phone,
    });
    // getOngkir();
  }, [user_info]);

  useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      totalWeight(products) > 0 &&
      user_info?.city
    ) {
      getOngkir();
    }
  }, [products?.length > 0, user_info]);

  useEffect(() => {
    getDeliveryMethods();
  }, []);

  useEffect(() => {
    setSelectedDeliveryMethod(deliveryMethods[0]);
  }, [deliveryMethods]);

  useTitle("Checkout" + pageTitle);

  return (
    <div className="bg-light-50">
      <Helmet>
        <meta name="description" content="MSB Motor." />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <main className="mx-auto max-w-7xl px-4 pb-24 pt-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="sr-only">Checkout</h1>

          <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            {/* Order summary */}
            <div>
              <div className="relative border p-4">
                <a
                  href="/my-profile"
                  className="absolute right-2 top-2 text-sm font-light uppercase"
                >
                  Ubah
                </a>

                <div className="text-sm font-light uppercase">
                  <label
                    htmlFor="email"
                    className="block text-sm font-light uppercase text-dark-900"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <p className="font-normal lowercase">{info.email}</p>
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="address"
                    className="block text-sm font-light uppercase text-dark-900"
                  >
                    Alamat Pengiriman
                  </label>
                  <div className="mt-1">
                    <p className="text-sm font-normal uppercase">
                      {/* Jl. Mayjend Sungkono 89, Surabaya, East Java{" "}
                      {info.postal_code}, Indonesia */}
                      {info.address} {info.address_info}{" "}
                      {isNaN(Number(info.province)) && isNaN(Number(info.city))
                        ? `${info.city},${info.province}`
                        : ""}{" "}
                      {info.postal_code}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h2 className="mt-10 text-sm font-light uppercase text-dark-900">
                  Metode Pengiriman
                </h2>
                <RadioGroup
                  // value={selectedVariant}
                  // onChange={setSelectedVariant}
                  className="mt-2"
                >
                  <RadioGroup.Label className="sr-only">
                    Pilih metode pengiriman{" "}
                  </RadioGroup.Label>
                  <div className="flex flex-col border-y border-dark-400">
                    {ongkir &&
                      ongkir.length > 0 &&
                      ongkir.map((list, index) => (
                        <RadioGroup.Option
                          key={index}
                          value="1"
                          className={({ active, checked }) =>
                            classNames(
                              active ? "bg-dark-200" : "",
                              checked ? " font-medium" : " text-dark-900 ",
                              "justify-left flex cursor-pointer items-center px-3 py-1 text-sm focus:outline-none sm:flex-1"
                            )
                          }
                          onClick={() => {
                            if (list.service.toUpperCase() == "NOT FOUND") {
                              alert("kurir tidak tersedia");
                            } else {
                              if (user_info?.country) {
                                setShippingCost(
                                  grandTotal(products, 0) >=
                                    Number(
                                      process.env
                                        .REACT_APP_FLAT_ONGKIR_CONDITION
                                    )
                                    ? Number(process.env.REACT_APP_FLAT_ONGKIR)
                                    : Number(list.cost)
                                );
                                setDelivery(`POS - ${list.service}`);
                              } else {
                                setShippingCost(
                                  grandTotal(products, 0) >=
                                    Number(
                                      process.env
                                        .REACT_APP_FLAT_ONGKIR_CONDITION
                                    )
                                    ? Number(process.env.REACT_APP_FLAT_ONGKIR)
                                    : Number(list.cost[0].value)
                                );
                                setDelivery(
                                  `${
                                    list.service == "GRAB/GOJEK" ? "" : "JNE - "
                                  }${list.service}`
                                );
                              }
                            }
                          }}
                        >
                          <RadioGroup.Label
                            as="span"
                            className="flex w-full items-center justify-between py-2"
                          >
                            <div className="flex flex-col">
                              <span>
                                {user_info?.country
                                  ? "POS - "
                                  : list.service == "GRAB/GOJEK"
                                  ? ""
                                  : "JNE - "}
                                {list.service}
                              </span>
                              <span className="text-sm font-light uppercase text-dark-700">
                                {list.description}
                              </span>
                              <span className="text-sm font-light uppercase text-dark-700">
                                {user_info?.country
                                  ? list.etd
                                  : list.cost[0].etd}{" "}
                                hari
                              </span>
                            </div>
                            <span>
                              Rp{" "}
                              {user_info?.country
                                ? formatCurrency(list.cost)
                                : formatCurrency(list.cost[0].value)}
                            </span>
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <h2 className="mt-10 text-sm font-light uppercase text-dark-900 lg:mt-0">
                Ringkasan Pembelian
              </h2>

              <div className="mt-4 text-sm font-light">
                <h3 className="sr-only">Produk di keranjang</h3>
                <ul className="divide-y divide-dark-200">
                  {products.map((product) => (
                    <li key={product.id} className="flex py-2">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            product?.Product?.DetailImage === null
                              ? `${product?.Product?.product_image}`
                              : `${process.env.REACT_APP_BACKEND_API}/file/${product?.Product?.DetailImage?.path}`
                          }
                          alt=""
                          className="h-20 w-20 rounded-md object-cover"
                        />
                      </div>

                      <div className="ml-6 flex flex-1 flex-col">
                        <div className="flex">
                          <div className="min-w-0 flex-1">
                            <h4 className="">
                              <a
                                href={`/detail/${product.product_id}`}
                                className=" line-clamp-3 text-dark-900 hover:text-dark-800"
                              >
                                {product.product_name} {checkStock(product)}
                              </a>
                            </h4>
                            <p className="mt-1 text-dark-700">
                              {product.variant}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-1 items-end justify-between pt-2">
                          <p className="mt-1 text-dark-900">
                            {/* Rp {formatCurrency(product?.price)} ( x{" "}
                            {product.qty} ) */}
                            Rp {productPrice(product?.Product, product.qty)} ( x{" "}
                            {product.qty} )
                          </p>
                          <div className="ml-4">
                            <p>
                              Rp{" "}
                              {/* {formatCurrency(
                                Number(product?.price) * product.qty
                              )} */}
                              {formatCurrency(
                                Number(
                                  productPriceNum(product?.Product, product.qty)
                                ) * product.qty
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <dl className="space-y-4 border-t border-dark-200 py-4">
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Subtotal</dt>
                    <dd className="text-sm font-normal text-primary-600">
                      Rp {formatCurrency(grandTotal(products))}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Ongkir</dt>
                    <dd className="text-sm font-normal text-dark-900">
                      Rp {formatCurrency(shippingCost)}
                    </dd>
                  </div>
                  {/* <div className="flex items-center justify-between">
                    <dt className="text-sm">Taxes</dt>
                    <dd className="text-sm font-medium text-dark-900">$5.52</dd>
                  </div> */}
                  <div className="flex items-center justify-between border-t border-dark-200 pt-4">
                    <dt className="text-sm font-medium">Total</dt>
                    <dd className="text-sm font-medium text-dark-900">
                      Rp {formatCurrency(grandTotal(products, shippingCost))}
                    </dd>
                  </div>
                </dl>

                <div className="fixed bottom-0 left-0 right-0 border-t border-dark-200 bg-light-50 px-4 py-4 sm:px-6 md:flex md:justify-end md:gap-4 md:border-0 md:pl-24 md:pr-24">
                  <div className="flex flex-row items-end justify-end gap-2 pb-2 md:flex-col md:items-end md:justify-center md:gap-0 md:pb-0">
                    <dt className="pb-0.5 text-sm font-normal uppercase text-dark-500">
                      Total Pembayaran
                    </dt>
                    <dd className="text-base font-semibold text-primary-600">
                      Rp {formatCurrency(grandTotal(products, shippingCost))}
                    </dd>
                  </div>
                  <button
                    type="submit"
                    className="w-full border border-transparent bg-primary-600 px-4 py-3 text-sm font-normal uppercase text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-dark-50 md:mx-4 md:w-72"
                    onClick={() => checkoutOrder()}
                    // disabled={shippingCost == 0}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Konfirmasi Pembelian"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
