import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatCurrency, useTitle, grandTotal, productPrice } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import {
  deleteProductCart,
  updateProductQty,
} from "../redux/actions/productsAction";
import { Helmet } from "react-helmet-async";
import { checkStock } from "../helper/functions";

export default function CartPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.cart_list);
  const is_loading = useSelector((state) => state.products.cart_loading);
  const delete_loading = useSelector((state) => state.products.delete_loading);

  const removeProduct = (id) => {
    console.log("remove: ", id);
    dispatch(deleteProductCart(id));
  };

  const updateQty = (id, qty, product) => {
    console.log("update: ", id, qty, product);
    dispatch(updateProductQty(id, qty, product));
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   if (!token) {
  //     navigate("/auth/");
  //   } else {
  //     dispatch(autoLogin(navigate));
  //   }
  // }, []);

  useTitle("Shopping Cart" + pageTitle);

  return (
    <>
      <Helmet>
        <meta name="description" content="MSB Motor" />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      {delete_loading && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-[99999999999999] bg-dark-800 opacity-50">
          <div className="my-20 text-lg font-bold text-white"></div>
        </div>
      )}
      <div className="m-auto max-w-7xl bg-light-50 text-xs font-light">
        <div className="mx-auto px-4 py-4 md:px-6 lg:px-8">
          <h1 className="text-center text-xs font-semibold tracking-tight text-dark-900">
            Keranjang Belanja
          </h1>

          <div className="mt-4">
            {is_loading ? (
              <div className="flex justify-center">
                <img src="/msbmotor.png" />
              </div>
            ) : (
              <section aria-labelledby="cart-heading">
                <h2 id="cart-heading" className="sr-only">
                  Items in your shopping cart
                </h2>

                <ul className="divide-y divide-dark-200 border-b border-t border-dark-200">
                  {products?.map((product) => (
                    <li
                      key={product.id}
                      className="flex max-w-2xl items-start py-6"
                    >
                      <div className="w-48">
                        <img
                          src={product?.Product?.DetailImage === null ? `${product?.Product?.product_image}` : `${process.env.REACT_APP_BACKEND_API}/file/${product?.Product?.DetailImage?.path}`}
                          alt=""
                          className="h-36 w-36 object-cover object-center"
                        />
                      </div>

                      <div className="ml-4 flex h-36 w-full flex-col justify-between pl-2 md:ml-0 md:py-2">
                        <div className="flex flex-col justify-between">
                          <h4 className="text-xs">
                            <a
                              href={`/collections/detail/${product.product_id}`}
                              className="line-clamp-5 w-full font-light text-dark-900 hover:text-dark-800"
                            >
                              {product.product_name} {checkStock(product)}
                            </a>
                          </h4>
                          <div className="md:flex md:items-center md:justify-between">
                            <p className="mt-1 text-base font-medium text-primary-600">
                              Rp{" "}{product?.Product?.gross_qty > 0 && product?.Product?.gross_qty <= product?.qty ? <span className="line-through text-dark-400 font-normal">{formatCurrency(product?.Product?.min_price)}{" "}</span> : <></>}
                              {productPrice(product?.Product, product.qty)}
                            </p>
                            <p className="mt-1 hidden text-xs text-dark-500">
                              {product.variant}, {product.SizeVariant?.name}
                            </p>
                          </div>
                        </div>

                        <div className="mt-2 flex flex-1 items-end justify-between">
                          <p className="flex items-center space-x-2 text-xs text-dark-900">
                            {/* {product.inStock ? (
                            <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                          ) : (
                            <ClockIcon className="h-5 w-5 flex-shrink-0 text-dark-300" aria-hidden="true" />
                          )} */}
                            {/* <span>{product.inStock ? 'In stock' : `Will ship in ${product.leadTime}`}</span> */}
                            <button
                              type="button"
                              className="px-2 text-xs font-light text-primary-600 hover:text-primary-500"
                              onClick={() =>
                                updateQty(
                                  product.id,
                                  Number(product.qty) - 1,
                                  product
                                )
                              }
                            >
                              <span>-</span>
                            </button>
                            {product.qty}
                            <button
                              type="button"
                              className="text-xs font-light text-primary-600 hover:text-primary-500"
                              onClick={() =>
                                updateQty(
                                  product.id,
                                  Number(product.qty) + 1,
                                  product
                                )
                              }
                            >
                              <span>+</span>
                            </button>
                          </p>
                          <div className="ml-4">
                            <button
                              type="button"
                              className="text-xs font-light uppercase text-primary-600 hover:text-primary-500"
                              onClick={() => removeProduct(product.id)}
                            >
                              <span>Hapus</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            )}

            {/* Order summary */}
            <section aria-labelledby="summary-heading" className="mt-10">
              <div className="fixed bottom-0 left-0 right-0 grid h-28 grid-cols-2 border-dark-400 bg-light-50 md:flex md:h-16 md:justify-end md:border-0 md:pl-24 md:pr-24">
                {/* <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2> */}

                <div className="order-last col-span-2 px-4 pb-3 pt-1 md:col-span-1 md:w-96 md:py-3">
                  {products?.length > 0 && (
                    <button
                      type="submit"
                      className="h-full w-full border border-transparent bg-primary-600 px-4 text-sm font-medium uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-dark-50"
                      onClick={() => navigate("/checkout/")}
                    >
                      Lanjut ke Checkout
                    </button>
                  )}
                </div>

                <div className="col-span-2 flex h-full w-full flex-row items-center justify-start gap-2 px-4 pt-1 md:col-span-1 md:flex-col md:items-end md:justify-center md:gap-0">
                  <dt className="text-lg font-semibold text-primary-600">
                    TOTAL Rp {formatCurrency(grandTotal(products))}
                  </dt>
                  <dd className="hidden text-[10px] font-light uppercase text-dark-900">
                    * including VAT
                  </dd>
                  {/* <p className="mt-1 text-xs text-dark-500">
                  Shipping and taxes will be calculated at checkout.
                </p> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
