import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { authLogin } from "../../redux/actions/authAction";
import { useTitle } from "../../helper/functions";
import { pageTitle } from "../../helper/globals";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { api } from "../../configs/api";

export default function ChangePasswordPage() {
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    token: "",
    password: "",
    confirmation: "",
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  let from = location.state?.from;

  function myNavigate() {
    // console.log("In MyNavigate function page from: ", from);
    navigate("/auth/register/", { state: { from: from } });
  }

  useTitle("Reset Password" + pageTitle);

  const forgetPass = async () => {
    try {
      console.log("run forgetPass");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/auth/reset`,
        data: state,
      });

      if (response && response.status == "success") {
        console.log("resposne: ", response);
        setState({
          token: "",
          password: "",
          confirmation: "",
        });
        navigate("/auth");
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    let token = location?.search;
    if (token) {
      let newtoken = token.substring(1).split("=")[1];
      console.log("params reset: ", newtoken);
      setState({
        ...state,
        token: newtoken,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-dark-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-col justify-center px-8 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-left text-sm font-light uppercase tracking-tight text-dark-900">
            Reset your password
          </h2>
          <h3 className="mt-2 text-left text-xs font-light tracking-tight text-dark-900">
            We will send you an email with instructions on how to recover it
          </h3>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
                <label
                  htmlFor="email"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Password Confirmation
                </label>
                <div className="mt-1">
                  <input
                    id="confirmation"
                    name="confirmation"
                    type="password"
                    autoComplete="confirmation"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        confirmation: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <button
                  className="flex w-full justify-center border border-transparent bg-black px-4 py-2 text-xs font-light uppercase text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => forgetPass()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
