import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

const faqs = [
  {
    question: "When will my pre-order(s) arrive?",
    answer:
      "Usually we take 10-14 working days for pre-order items. After your order is ready, we will deliver your order and it may take 3-4 days to arrive to your address if you are in Indonesia, and may take longer if you are outside Indonesia.",
  },
  {
    question: "Where do you ship it from?",
    answer:
      "Our manufacturer is in Bali and we ship it from Bali.  We ship every Monday through Friday, excluding Indonesia's public holidays. Our cut off time for shipments is at 4pm. Orders after 4pm will be processed on the next working day.",
  },
  {
    question: "How can I track my orders?",
    answer:
      "We will send you the tracking number or resi for your package to your email address so you can track it anytime.",
  },
  {
    question: "Can I return, exchange, or issue refund for my items?",
    answer:
      "Please check out our <a href='/return-policy' class='underline'>return policy</a>.",
  },
  {
    question: "Where can I message to ask about my orders?",
    answer:
      "For any questions or complaints, you can email us at <a href='mailto:admin@msbmotor.id' class='underline'>admin@msbmotor.id</a> or Text us on whatsapp <a href='https://wa.me/6281231520688' class='underline' target='_blank'>+62 81231520688</a> for fast response.",
  },
  // More questions...
];

export default function FAQ() {
  useTitle("FAQ" + pageTitle);

  return (
    <div className="bg-light-50">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-24 lg:px-8 lg:py-24">
        <div className="mx-auto max-w-4xl divide-y divide-dark-900/10">
          <h2 className="text-base font-light uppercase leading-10 tracking-tight text-dark-900">
            Frequently asked questions
          </h2>
          <dl className="mt-4 space-y-2 divide-y divide-dark-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-2">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-dark-900">
                        <span className="text-xs font-normal leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-0 pr-12">
                      <p
                        className="text-xs font-light leading-normal text-dark-900"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      >
                        {/* {faq.answer} */}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
