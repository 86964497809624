import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../configs/api";
import { useTitle } from "../../helper/functions";
import { pageTitle } from "../../helper/globals";
import { Helmet } from "react-helmet-async";
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function RegisterPage() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    password: "",
    province: "",
    city: "",
    address: "",
    address_info: "",
    postal_code: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [isShowError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useTitle("Register" + pageTitle);

  const location = useLocation();
  let from = location.state?.from;

  // console.log("In register page, from: ", from);

  const register = async () => {
    try {
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/users`,
        data: state,
      });

      if (response && response.status == "success") {
        console.log("resposne: ", response);
        setState({
          first_name: "",
          last_name: "",
          email: "",
          country: "",
          password: "",
          province: "",
          city: "",
          address: "",
          address_info: "",
          postal_code: "",
          phone: "",
        });
        alert(
          "Registration success! Please check your email for account verification."
        );
        await navigate("/auth/", { state: { from: from } });
      }
    } catch (error) {
      console.log("error: ", error);
      setErrorMessage(error?.response?.data?.message);
      setShowError(true);
    }
  };

  const getCountries = async () => {
    try {
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/country`,
      });

      if (response && response.status == "success") {
        setCountries(response.data);
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const getProvinces = async () => {
    try {
      console.log("run province fetch");
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/province`,
      });

      if (response && response.status == "success") {
        console.log("resposne: ", response);
        setProvinces(response.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getCities = async () => {
    try {
      console.log("run province fetch");
      const { data: response } = await axios({
        method: "GET",
        url: `${api}/api/v1/raja-ongkir/location/city?province=${state.province}`,
      });

      if (response && response.status == "success") {
        console.log("resposne: ", response);
        setCities(response.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }

    getCountries();
    getProvinces();
  }, []);

  useEffect(() => {
    if (state?.province) {
      setCities([]);
      setState({
        ...state,
        city: "",
      });
      getCities();
    }
  }, [state.province, state.country]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-dark-50">
        <body class="h-full">
        ```
      */}
      {isShowError && (
        <div className="fixed z-50 mt-0 w-full bg-danger-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-danger-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-danger-800">
                Registration Error: {errorMessage}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-danger-50 p-1.5 text-danger-500 hover:bg-danger-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  onClick={() => setShowError(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex min-h-full flex-col justify-center px-8 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img
            className="mx-auto h-10 w-auto"
            src="/client-logo.png"
            alt="MSB Motor"
          /> */}
          {/* <div className="mx-auto h-10 w-full text-center uppercase text-lg font-light">
            MSB Motor
          </div> */}
          <h2 className="mt-6 text-left text-sm font-normal uppercase tracking-wider text-dark-900">
            Daftar Akun
          </h2>
          {/* <p className="mt-2 text-center text-sm text-dark-600">
            Or{' '}
            <a href="#" className="font-medium text-primary-600 hover:text-primary-500">
              start your 14-day free trial
            </a>
          </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Nama depan
                </label>
                <div className="mt-1">
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Nama belakang
                </label>
                <div className="mt-1">
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Nomor Telepon / WhatsApp
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    autoComplete="phone"
                    required
                    value={state.phone}
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Alamat email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password-repeat"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Konfirmasi Password
                </label>
                <div className="mt-1">
                  <input
                    id="password-repeat"
                    name="password-repeat"
                    type="password"
                    autoComplete="current-password-repeat"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        passwordRepeat: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Alamat
                </label>
                <div className="mt-1">
                  <input
                    id="address"
                    name="address"
                    type="address"
                    autoComplete="address"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        address: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="address-2"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Info Gedung / Apartemen
                </label>
                <div className="mt-1">
                  <input
                    id="address-2"
                    name="address-2"
                    type="address"
                    autoComplete="address-2"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        address_info: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              {/* <div>
                <label
                  htmlFor="address-2"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Country (select if outside Indonesia)
                </label>
                <select
                  value={state.country}
                  className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  onChange={(e) => {
                    console.log("on change: ", e.target.value);
                    setState({
                      ...state,
                      country: e.target.value,
                      province: "",
                      city: "",
                    });
                  }}
                >
                  <option value={""}>Choose Country</option>
                  {countries?.length > 0 &&
                    countries?.map((list, index) => (
                      <option key={index} value={list.country_id}>
                        {list.country_name}
                      </option>
                    ))}
                </select>
              </div> */}

              {state.country == "" ? (
                <>
                  <div>
                    <label
                      htmlFor="address-2"
                      className="block text-xs font-light uppercase text-dark-700"
                    >
                      Provinsi
                    </label>
                    <select
                      value={state.province}
                      className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      onChange={(e) => {
                        console.log("on change: ", e.target.value);
                        setState({
                          ...state,
                          province: e.target.value,
                          city: "",
                        });
                      }}
                    >
                      <option value={null}>Pilih Provinsi</option>
                      {provinces?.length > 0 &&
                        provinces?.map((list, index) => (
                          <option key={index} value={list.province_id}>
                            {list.province}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="address-2"
                      className="block text-xs font-light uppercase text-dark-700"
                    >
                      Kota
                    </label>
                    <select
                      value={state.city}
                      className="mt-1 block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      onChange={(e) => {
                        console.log("on change: ", e.target.value);
                        setState({
                          ...state,
                          city: e.target.value,
                        });
                      }}
                    >
                      <option value={null}>Pilih Kota</option>
                      {cities?.length > 0 &&
                        cities?.map((list, index) => (
                          <option key={index} value={list.city_id}>
                            {list.city_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <label
                      htmlFor="address-2"
                      className="block text-xs font-light uppercase text-dark-700"
                    >
                      Province
                    </label>
                    <div className="mt-1">
                      <input
                        id="province"
                        name="province"
                        type="text"
                        autoComplete="province"
                        required
                        className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                        onChange={(e) => {
                          setState({
                            ...state,
                            province: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="address-2"
                      className="block text-xs font-light uppercase text-dark-700"
                    >
                      City
                    </label>
                    <div className="mt-1">
                      <input
                        id="city"
                        name="city"
                        type="text"
                        autoComplete="city"
                        required
                        className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                        onChange={(e) => {
                          setState({
                            ...state,
                            city: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* <div>
                <label
                  htmlFor="address-2"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  City
                </label>
                <select
                  value={state.city}
                  className="mt-1 w-full border px-4 py-2 text-base text-dark-900 focus:outline-none"
                  onChange={(e) => {
                    console.log("on change: ", e.target.value);
                    setState({
                      ...state,
                      city: e.target.value,
                    });
                  }}
                >
                  <option value={null}>Choose City</option>
                  {cities?.length > 0 &&
                    cities?.map((list, index) => (
                      <option key={index} value={list.city_id}>
                        {list.city_name}
                      </option>
                    ))}
                </select>
              </div> */}

              {/* <div>
                <label
                  htmlFor="province"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Province
                </label>
                <div className="mt-1">
                  <input
                    id="province"
                    name="province"
                    type="province"
                    autoComplete="province"
                    required
                    value={state.province}
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        province: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="city"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  City
                </label>
                <div className="mt-1">
                  <input
                    id="city"
                    name="city"
                    type="city"
                    autoComplete="city"
                    required
                    value={state.city}
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        city: e.target.value,
                      });
                    }}
                  />
                </div>
              </div> */}

              {/* <div>
                <label
                  htmlFor="subdistrict"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Sub-District
                </label>
                <div className="mt-1">
                  <input
                    id="subdistrict"
                    name="subdistrict"
                    type="subdistrict"
                    autoComplete="subdistrict"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        subdistrict: e.target.value,
                      });
                    }}
                  />
                </div>
              </div> */}

              <div>
                <label
                  htmlFor="postcode"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Kode Pos
                </label>
                <div className="mt-1">
                  <input
                    id="postcode"
                    name="postcode"
                    type="postcode"
                    autoComplete="postcode"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        postal_code: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="items-left flex flex-col gap-2">
                <div className="flex hidden items-center">
                  <input
                    id="aggree"
                    name="agree"
                    type="checkbox"
                    className="h-4 w-4 border-dark-900 text-primary-900 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="agree"
                    className="ml-2 block text-xs font-light text-dark-900"
                  >
                    Subscribe to our newsletter
                  </label>
                </div>
                {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 border-dark-900 text-primary-900 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-xs font-light text-dark-900"
                  >
                    Remember me
                  </label>
                </div> */}
              </div>

              <div>
                <button
                  className="flex w-full justify-center border border-transparent bg-primary-600 px-4 py-2 text-xs font-light uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => register()}
                >
                  Buat Akun
                </button>
              </div>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-dark-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-light-100 px-2 text-xs font-light uppercase text-dark-500">
                    Sudah punya akun?
                  </span>
                </div>
              </div>

              <div className="pt-2">
                <button
                  className="flex w-full justify-center border border-transparent bg-black px-4 py-2 text-xs font-light uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => navigate("/auth/")}
                >
                  Login
                </button>
              </div>
              {/* <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-dark-300 bg-light-50 py-2 px-4 text-sm font-medium text-dark-500 shadow-sm hover:bg-dark-50"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-dark-300 bg-light-50 py-2 px-4 text-sm font-medium text-dark-500 shadow-sm hover:bg-dark-50"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md border border-dark-300 bg-light-50 py-2 px-4 text-sm font-medium text-dark-500 shadow-sm hover:bg-dark-50"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
