import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, productPrice } from "../helper/functions";
import { getCategory, getProduct } from "../redux/actions/productsAction";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import LazyImage from "../components/lazyImage";

export default function Products() {
  const [searchParams] = useSearchParams();
  const products = useSelector((state) => state.products.product_list);
  const categories = useSelector((state) => state.products.category_list);
  const is_loading = useSelector((state) => state.products.loading);
  const [inputValue, setInputValue] = useState("");
  const typingTimer = useRef(null);
  const doneTypingInterval = 1000;
  const dispatch = useDispatch();

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    dispatch(getProduct(null, inputValue));
  };

  useTitle("Latest Products" + pageTitle);

  // console.log("Category: ", searchParams.get("category"));
  // console.log("Name: ", searchParams.get("name"));
  const category = searchParams.get("category");

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/js/animate-on-scroll.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(getProduct(category));
    dispatch(getCategory());
  }, []);
  return (
    <div className="relative bg-light-100">
      <Helmet>
        <meta name="description" content="MSB Motor description." />
      </Helmet>
      {/* Mobile menu */}
      <main>
        <div className="bg-light-100">
          <div className="m-auto mt-4 flex max-w-7xl flex-wrap items-center justify-start gap-2 px-2">
            {/* {categories.length > 0 &&
              categories.map((category) => (
                <div
                  className="min-w-[100px] cursor-pointer bg-white px-4 py-1 text-center text-dark-700 hover:bg-primary-600 hover:text-light-100"
                  onClick={() => dispatch(getProduct(category.id))}
                  key={category.id}
                >
                  {category.name}
                </div>
              ))} */}
            <input
              type="text"
              value={inputValue}
              placeholder="Cari produk..."
              onChange={(e) => setInputValue(e.target.value)}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              className="w-full border-dark-300 focus:ring-primary-500 focus:accent-primary-500 focus:border-primary-500 py-1 md:hidden"
            />
          </div>
          <div className="mx-auto max-w-2xl px-2 py-4 sm:py-4 lg:max-w-7xl">
            <h2
              id="our-products"
              className="text-xl font-bold text-dark-900"
            ></h2>

            {is_loading ? (
              <div className="flex justify-center">
                <img src="/msbmotor.png" />
              </div>
            ) : (
              <div className="js-show-on-scroll m-auto grid max-w-7xl grid-cols-2 items-center justify-start gap-5 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
                {products.map(
                  (product) =>
                    product.inStock && (
                      <>
                      <a
                        key={product.id}
                        href={`collections/detail/${product.id}`}
                        className="flex justify-center"
                      >
                        <div className="aspect-[2/3] bg-white shadow-md relative h-full">
                          <div className="js-show-on-scroll relative flex h-full w-fit flex-col overflow-hidden transition-opacity duration-700 ease-in">
                            <LazyImage
                              src={product?.DetailImage === null ? `${product?.product_image}` : `${process.env.REACT_APP_BACKEND_API}/file/${product?.DetailImage?.path}`}
                              // src="https://down-id.img.susercontent.com/file/sg-11134201-23010-ctu7hma06fmv6b"
                              alt=""
                              className="product-image-max-height aspect-1 w-fit object-cover object-center lg:object-cover"
                            />
                            <div className="py-1 px-2 flex flex-col justify-between flex-grow">
                              <div className="relative mt-0 flex items-center justify-between gap-4 px-2 py-1 sm:px-0">
                                <h3 className="text-sm font-normal tracking-tight leading-tight text-dark-900 line-clamp-3">
                                  {product.name}
                                </h3>
                              </div>
                              <p className="relative text-lg font-medium text-primary-600">
                                <span className="pr-1 text-sm">Rp</span>
                                {productPrice(product, 1)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                      </>
                    )
                )}
              </div>
            )}
          </div>
        </div>
      </main>
      <a
        href="#our-products"
        className="fixed bottom-4 right-4 z-50 rounded-lg bg-dark-100/50"
      >
        <ChevronUpIcon className="h-8 w-8 text-dark-500" />
        {/* Back to Top */}
      </a>
    </div>
  );
}
