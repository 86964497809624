import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import {
  ChatBubbleOvalLeftIcon,
  CheckCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MeasurementGuideContent from "../components/info/measurement-guide-content";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { getProductDetail, updateCart } from "../redux/actions/productsAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products.detail);
  const is_loading = useSelector((state) => state.products.detail_loading);
  const cart_update = useSelector((state) => state.products.cart_loading);
  const [qty, setQty] = useState(1);
  const [sizeVariant, setSize] = useState(null);
  const [selectedVariantStock, setSelectedVariantStock] = useState(1);

  useTitle(product?.name + pageTitle);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const [selectedVariant, setSelectedVariant] = useState(null);

  const cartUpdate = async () => {
    await dispatch(updateCart(product, selectedVariant, sizeVariant, qty));
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const updateCartData = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/auth/", { state: { from: location.pathname } });
    } else {
      console.log("run update cart", selectedVariant);
      console.log("size variant: ", sizeVariant);
      if (product && product.has_variant) {
        if (selectedVariant && sizeVariant) {
          cartUpdate(false);
        } else {
          alert("Please select Color and Size.");
        }
      } else {
        cartUpdate(false);
      }
    }
  };

  const setVariant = (e) => {
    console.log("set variant: ", e);

    setSelectedVariant(e);
    setSize(null);
  };

  const setSizeVariant = (e) => {
    console.log("set variant: ", e);

    setSize(e);
    setSelectedVariantStock(e.stock);
    console.log("selected stock: ", e.stock);
  };

  useEffect(() => {
    dispatch(getProductDetail(params.id));
  }, []);

  useEffect(() => {
    setSelectedVariant(
      product?.ProductColorSizes?.length > 0
        ? product.ProductColorSizes[0]
        : null
    );

    if (!product?.has_variant) {
      setSelectedVariantStock(product?.stock);
    }
  }, [product]);

  useEffect(() => {
    console.log("variant selected: ", selectedVariant);
    console.log("images: ", selectedVariant?.images);
  }, [selectedVariant]);

  function toggleText() {
    console.log("Hello world!");
    var descText = document.getElementById("txtDescription");
    var button = document.getElementById("btnShowMore");

    if (descText.classList.contains("line-clamp-6")) {

      // hide the more text
      descText.classList.remove("line-clamp-6");

      // change text of the button
      button.innerHTML = "Tutup";
    } else {

      // Hide the more text
      descText.classList.add("line-clamp-6");

      // change text of the button
      button.innerHTML = "Baca selengkapnya";
    }
}

  return (
    <div className="bg-light-50 pb-48">
      <Helmet>
        <meta name="description" content="MSB Motor description." />
      </Helmet>

      {/* Modal konfirmasi success add to cart */}
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 mt-16 flex items-start px-4 py-6 sm:items-start"
      >
        <div className="flex w-full flex-col items-center space-y-4 bg-light-50 sm:items-start">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-success-100 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex w-0 flex-1 items-center justify-between">
                    <p className="text-sm font-medium text-dark-900">
                      Successfully added to cart!
                    </p>
                    {/* <p className="mt-1 text-sm text-dark-500">
                      Anyone with a link can now view this file.
                    </p> */}
                    <a
                      href="/cart"
                      className="ml-3 flex-shrink-0 rounded-md text-sm font-medium text-black underline hover:text-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    >
                      View Cart
                    </a>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-dark-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-light-50 py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-normal uppercase leading-6 text-dark-900">
                            More Info
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="bg-light-50 text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 pb-24 sm:px-6">
                        {/* Your content */}
                        <p className="text-xs font-light">SHIPPING MSB MOTOR</p>
                        <div className="mt-8 text-xs font-light">
                          <h2 className="uppercase">Measurement Guide</h2>
                          <MeasurementGuideContent />
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {is_loading ? (
        <div className="flex justify-center">
          <img src="/msbmotor.png" />
        </div>
      ) : (
        <main className="mx-auto max-w-7xl md:pt-8 md:px-6 lg:px-8">
          {/* <nav className="flex p-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <a
                    href="/"
                    className="text-dark-400 hover:text-dark-500"
                  >
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <li key={product?.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-dark-400"
                    aria-hidden="true"
                  />
                  <a
                    href="#"
                    className="ml-4 text-xs font-light text-dark-500 hover:text-dark-700"
                    aria-current="page"
                  >
                    {product?.name}
                  </a>
                </div>
              </li>
            </ol>
          </nav> */}
          <div className="mx-auto max-w-7xl lg:max-w-none">
            {/* Product */}
            <div className="md:grid md:grid-cols-10 lg:items-start lg:gap-x-8">
              {/* Image gallery */}
              <div className="group flex h-full w-full items-start justify-center md:col-span-5 md:justify-end">
                <div className="flex max-h-screen snap-x snap-mandatory overflow-x-auto whitespace-nowrap md:block md:max-h-96 md:snap-y md:snap-mandatory md:overflow-y-auto md:pr-4 lg:max-h-[600px]">
                  
                  <img
                    src={product?.DetailImage === null ? `${product?.product_image}` : `${process.env.REACT_APP_BACKEND_API}/file/${product?.DetailImage?.path}`}
                    alt=""
                    className="product-image-max-height aspect-1 w-fit object-cover object-center lg:object-contain"
                  />
                </div>
                {/* <div className="ml-4 hidden max-h-screen w-10 snap-x snap-mandatory overflow-x-auto whitespace-nowrap opacity-0 transition delay-150 duration-300 ease-in-out group-hover:opacity-100 md:max-h-96 md:snap-y md:snap-mandatory md:overflow-y-auto md:pr-4 lg:block lg:max-h-[600px]">
                  {product?.DetailImage?.filter(
                    (x) => x.color_variant_id === selectedVariant?.id
                  ).map((image) => (
                    <img
                      src={`${process.env.REACT_APP_BACKEND_API}/file/${image.path}`}
                      alt=""
                      className="product-image-max-height w-fit snap-center object-contain object-center md:max-h-96 md:snap-start lg:max-h-[600px]"
                    />
                  ))}
                </div> */}
              </div>

              {/* Product info */}
              <div className="px-4 py-2 font-light md:col-span-5 lg:mt-0">
                <h1 className="mb-2 text-base font-medium tracking-tight text-dark-900">
                  {product?.name}
                </h1>

                <div className="mt-0">
                  <h2 className="sr-only">Product information</h2>
                  {sizeVariant ? (
                    <div className="flex gap-2 text-xl">
                      <span className="text-sm">
                        {Number(sizeVariant.discount) <
                        Number(sizeVariant.price) ? (
                          <>
                            <span>
                              {sizeVariant.discount
                                ? sizeVariant.discount.toLocaleString()
                                : ""}
                              {" IDR "}
                            </span>
                            <span className="text-sm tracking-tight text-dark-400 line-through">
                              {sizeVariant.price
                                ? sizeVariant.price.toLocaleString() + " IDR"
                                : ""}
                            </span>
                          </>
                        ) : (
                          <span>
                            {sizeVariant.price
                              ? sizeVariant.price.toLocaleString() + " IDR"
                              : ""}
                          </span>
                        )}
                      </span>
                    </div>
                  ) : (
                    <p className="text-lg font-semibold tracking-tight text-primary-600">
                      Rp <span className={product?.gross_qty > 0 && product?.gross_qty <= qty ? "line-through text-dark-400 font-normal" : ""}>{product?.min_price.toLocaleString()}</span>{" "}
                      {/* {product?.max_price != product?.min_price
                        ? "- " + product?.max_price.toLocaleString()
                        : ""} */}
                      {product?.gross_qty > 0 && product?.gross_qty <= qty
                        ? `${product?.gross_price.toLocaleString()}`
                        : ``}
                    </p>
                    )}
                {product?.gross_price > 0 && product?.gross_qty > 0 && process.env.REACT_APP_SHOW_GROSS_PRICE == 1 && (
                  <p className="text-sm font-normal tracking-tight text-gray-500">
                    Harga Grosir{" "}
                    <span
                      className="text-gray-500"
                    >
                      Rp{" "}{product?.gross_price.toLocaleString()}
                    </span>{" "}
                    {product?.gross_qty > 0 && product?.gross_qty > 0
                      ? ` minimum pembelian ${product?.gross_qty} pcs`
                      : ""}
                  </p>
                )}
                </div>

                <div className="mt-6">
                  <h3 className="text-xs font-medium pb-1 text-dark-600">Spesifikasi</h3>

                  <div
                    className="space-y-6 text-sm font-light normal-case text-dark-700"
                    // dangerouslySetInnerHTML={{ __html: product?.description }}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product?.spesification}
                  </div>
                </div>

                <div className="mt-6">
                  <h3 className="sr-only">Description</h3>

                  <div
                    name="txtDescription"
                    id="txtDescription"
                    className="space-y-6 text-sm font-light normal-case text-dark-700 line-clamp-6"
                    // dangerouslySetInnerHTML={{ __html: product?.description }}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {product?.description}
                  </div>
                  <button onClick={toggleText} id="btnShowMore"
                    class="mt-3 text-primary-600 font-medium text-xs duration-300 hover:text-primary-700">
                      Baca selengkapnya
                  </button>
                </div>

                <div className="mt-6">

                  {/* Size picker */}
                  <div className="mt-4 hidden">
                    <div className="flex items-center justify-between">
                      {/* <h2 className="text-xs font-light text-dark-900">
                        {product?.has_variant && `Pilih Varian`}
                      </h2> */}
                      {/* <a href="#" className="text-xs font-light text-primary-600 hover:text-primary-500">
                          See sizing chart
                        </a> */}
                    </div>

                    <RadioGroup
                      value={selectedVariant}
                      onChange={setVariant}
                      className="mt-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        {" "}
                        Choose Variant{" "}
                      </RadioGroup.Label>
                      <div className="flex flex-col border-y border-dark-400">
                        {product &&
                          product?.ProductColorSizes.map((variant) => (
                            <RadioGroup.Option
                              key={variant.id}
                              value={variant}
                              className={({ active, checked }) =>
                                classNames(
                                  // variant.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                  active ? "" : "",
                                  checked
                                    ? "bg-dark-100 font-medium"
                                    : " text-dark-900 ",
                                  // variant.inStock
                                  //   ? "text-dark-900 hover:bg-dark-100"
                                  //   : "text-dark-400",
                                  "justify-left flex cursor-pointer items-center px-3 py-1 text-xs uppercase hover:bg-dark-200 focus:outline-none sm:flex-1"
                                )
                              }
                              // disabled={!variant.inStock}
                            >
                              <RadioGroup.Label as="span">
                                {variant.name}
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="mt-4 hidden">

                    <RadioGroup
                      value={sizeVariant}
                      onChange={setSizeVariant}
                      className="mt-2"
                    >
                      <RadioGroup.Label className="sr-only">
                        {" "}
                        Choose Variant{" "}
                      </RadioGroup.Label>
                      <div className="flex flex-col border-y border-dark-400">
                        {selectedVariant?.sizes
                          .sort(function (a, b) {
                            return a.index - b.index;
                          })
                          .map((size) => (
                            <RadioGroup.Option
                              key={size.id}
                              value={size}
                              className={({ active, checked }) =>
                                classNames(
                                  // variant.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                  active ? "" : "",
                                  checked ? "bg-dark-100 font-medium" : " ",
                                  size.stock > 0
                                    ? "text-dark-900 hover:bg-dark-200"
                                    : "text-dark-400",
                                  "justify-left flex cursor-pointer items-center px-3 py-1 text-xs uppercase focus:outline-none sm:flex-1"
                                )
                              }
                              disabled={!size.stock > 0 && !product?.is_po}
                            >
                              <RadioGroup.Label
                                as="span"
                                className="flex w-full justify-between"
                              >
                                <span>{size.name}</span>
                                <span>
                                  {Number(size.stock) <= 0
                                    ? "Out of Stock"
                                    : ``}
                                </span>
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="mt-4">
                    <h2 className="text-xs font-light text-dark-900">
                      {product?.has_variant && `Quantity`}
                    </h2>
                    <div className="mt-2 flex items-center gap-1">
                      <button
                        type="button"
                        className="h-6 w-6 text-primary-600 hover:text-primary-500"
                        onClick={() => setQty(qty - 1)}
                      >
                        <MinusCircleIcon className="h-6 w-6 stroke-1" />
                      </button>
                      <input
                        id="qty"
                        name="qty"
                        type="number"
                        value={qty}
                        className="block w-24 appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-xs"
                        onChange={(e) => {
                          setQty(e.target.value);
                        }}
                        disabled={false}
                      />
                      <button
                        type="button"
                        className="h-6 w-6 text-primary-600 hover:text-primary-500"
                        onClick={() => setQty(Number(qty) + 1)}
                      >
                        <PlusCircleIcon className="h-6 w-6 stroke-1" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-col gap-2">
                    {qty <= selectedVariantStock ? (
                      <button
                        className="flex w-full flex-1 items-center justify-center border border-primary-500 bg-primary-600 px-8 py-3 text-xs font-light uppercase text-dark-50 shadow-lg hover:border-dark-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2 focus:ring-offset-dark-50 sm:w-full md:max-w-sm"
                        onClick={updateCartData}
                        // disabled={cart_update || !sizeVariant}
                      >
                        {cart_update ? "Processing..." : "Masukkan Keranjang"}
                      </button>
                    ) : (
                      <button
                        className="flex w-full flex-1 items-center justify-center border border-dark-900 px-8 py-3 text-xs font-light uppercase text-dark-900 shadow-lg hover:border-dark-400 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2 focus:ring-offset-dark-50 sm:w-full md:max-w-sm"
                        onClick={updateCartData}
                        disabled={cart_update || !sizeVariant}
                      >
                        {cart_update ? "Processing..." : "Pre Order (30 day)"}
                      </button>
                    )}

                    <a
                      href={"https://wa.me/6281231520688?text=Saya%20ingin%20tanya%20mengenai%20produk%20ini:%20"+product?.name}
                      className="flex gap-1 w-fit flex-1 items-center justify-center px-2 py-2 text-xs font-light text-primary-600 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2 focus:ring-offset-dark-50 hover:border border-primary-600"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ChatBubbleOvalLeftIcon
                        className="h-6 w-6 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="">Tanya lewat whatsapp</span>
                    </a>
                  </div>
                </div>

                <section aria-labelledby="details-heading" className="mt-12">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  <button
                    className="hidden text-xs font-light uppercase"
                    onClick={() => setOpen(true)}
                  >
                    More info
                  </button>
                </section>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}
