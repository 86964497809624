import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Transition } from "@headlessui/react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import SimpleAlertBox from "../../components/alert/simpleAlertBox";
import { api } from "../../configs/api";
import { useTitle } from "../../helper/functions";
import { pageTitle } from "../../helper/globals";

export default function LoginPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  let from = location.state?.from;

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function myNavigate() {
    // console.log("In MyNavigate function page from: ", from);
    navigate("/auth/register/", { state: { from: from } });
  }

  useTitle("Reset Password" + pageTitle);

  const forgetPass = async () => {
    try {
      console.log("run forgetPass");
      const { data: response } = await axios({
        method: "POST",
        url: `${api}/api/v1/auth/forget`,
        data: state,
      });

      if (response && response.status == "success") {
        console.log("response: ", response);
        setState({
          email: "",
        });
        navigate("/auth");
      }
    } catch (error) {
      // console.log("error: ", error);
      setErrorMessage(error?.response?.data?.message);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>

      {/* Modal error message */}
      <Transition show={showAlert}>
        <SimpleAlertBox error={true} message={errorMessage} />
      </Transition>

      <div className="flex min-h-full flex-col justify-center px-8 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-left text-base font-normal text-dark-900">
            Atur ulang password anda
          </h2>
          <h3 className="mt-2 text-left text-sm font-light text-dark-900">
            Kami akan mengirimkan email beserta cara mengatur ulang password anda
          </h3>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-light uppercase text-dark-700"
                >
                  Alamat email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none border border-dark-900 px-3 py-2 placeholder-dark-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div>
                <button
                  className="flex w-full justify-center border border-transparent bg-primary-600 px-4 py-2 text-sm font-normal uppercase text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => forgetPass()}
                >
                  Kirim email pengaturan ulang
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
