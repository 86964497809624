const initState = {
  user: {},
  user_info: null,
  loading: false,
  error: null,
  token: null,
  hasToken: false,
};

const authReducers = (state = initState, action) => {
  switch (action.type) {
    case "AUTO_LOGIN_SUCCESS":
      return {
        ...state,
        user_info: action.payload,
        hasToken: true,
        loading: false,
      };
    case "SAVE_TOKEN":
      console.log("save token:", action.token);
      return {
        ...state,
        token: action.token,
        hasToken: true,
      };
    case "LOGOUT":
      console.log("logout");
      return {
        ...state,
        token: null,
        user_info: null,
        user: {},
        hasToken: false,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducers;
