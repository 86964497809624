import { Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

export default function SimpleAlertBox(props) {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 mt-10 flex items-start px-4 py-6 sm:items-start"
    >
      <div className="flex w-full flex-col items-center space-y-4 bg-light-50 sm:items-start">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={true}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-light-50 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-6 w-6 text-danger-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex w-0 flex-1 items-center justify-start gap-2">
                  <p className="text-sm font-medium text-dark-900">
                    Login Error!
                  </p>
                  <p className="text-sm text-dark-500">{props?.message}</p>
                </div>
                {/* <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-light-50 text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                    
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
