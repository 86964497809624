import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
  useTitle("Contact Us" + pageTitle);

  return (
    <div className="bg-light-50 py-24 sm:py-32">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-dark-100 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-base font-light uppercase tracking-tight text-dark-900">
                Get in touch
              </h2>
              {/* <p className="mt-2 leading-7 text-dark-900">
                Quam nunc nunc eu sed. Sed rhoncus quis ultricies ac
                pellentesque.
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              <div className="p-4">
                <h3 className="text-xs font-light uppercase leading-7 text-dark-900">
                  Support
                </h3>
                <dl className="mt-1 space-y-1 text-sm leading-6 text-dark-900">
                  <div className="flex items-center gap-2 text-xs font-light text-dark-900">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon
                        className="h-4 w-4 text-dark-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-xs font-light text-dark-900"
                        href="mailto:admin@msbmotor.id"
                        target="_blank"
                        rel="noreferrer"
                      >
                        admin@msbmotor.id
                      </a>
                    </dd>
                  </div>
                  <div className="flex items-center gap-2 text-xs font-light text-dark-900">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon
                        className="h-4 w-4 text-dark-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-xs font-light text-dark-900"
                        href="tel:+628123456789"
                      >
                        +628123456789
                      </a>
                    </dd>
                  </div>
                  <div className="flex items-center gap-2 text-xs font-light text-dark-900">
                    <dt className="flex-none">
                      <span className="sr-only">WhatsApp</span>
                      <ChatBubbleBottomCenterIcon
                        className="h-4 w-4 text-dark-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="text-xs font-light text-dark-900"
                        href="https://wa.me/6281231520688"
                        target="_blank"
                        rel="noreferrer"
                      >
                        WA: +6281231520688
                      </a>
                    </dd>
                  </div>
                  {/* <div className="flex items-center gap-2 text-xs font-light text-dark-900">
                    <dt className="flex-none">
                      <span className="sr-only">WhatsApp</span>
                      <MapPinIcon
                        className="h-4 w-4 text-dark-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      Jalan Pantai Berawa no 43a Kuta, KAB. Badung, Bali, 80361
                      <a
                        href="https://goo.gl/maps/hsmqVPTkqBHraCQT8"
                        className="ml-1 whitespace-nowrap text-xs font-light tracking-tighter text-dark-500"
                        target="_blank"
                        rel="noreferrer"
                      >
                        find us on google
                      </a>
                    </dd>
                  </div> */}
                </dl>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
            <div>
              <h2 className="text-base font-light uppercase tracking-tight text-dark-900">
                Find Us
              </h2>
              {/* <p className="mt-4 leading-7 text-dark-900">
                Consequat sunt cillum cillum elit sint. Qui occaecat nisi in
                ipsum commodo.
              </p> */}
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">

              <div className="p-4">
                <h3 className="text-xs font-light uppercase leading-7 text-dark-900">
                  Shopee
                </h3>
                <div className="flex flex-col">
                  <a
                    className="text-xs font-light text-dark-900"
                    href="https://shopee.co.id/msbmotor"
                  >
                    MSB Motor
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
