import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";

export default function PaymentSuccess() {
  useTitle("Payment Successful" + pageTitle);

  return (
    <main className="grid min-h-full place-items-center bg-light-50 px-6 py-24 sm:py-32 lg:px-8">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <div className="text-center">
        <p className="text-xs font-light uppercase text-dark-600">
          Payment Successful
        </p>
        <h1 className="mt-4 text-xl font-light tracking-tight text-dark-900 sm:text-5xl">
          Thank you for your payment
        </h1>
        <p className="mt-6 text-xs font-light leading-7 text-dark-600">
          We will process your order as soon as possible.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/products"
            className="bg-dark-900 px-4 py-2 text-xs font-light uppercase text-white shadow-sm hover:bg-dark-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark-600"
          >
            Continue Shopping
          </a>
          {/* <a href="#" className="text-sm font-semibold text-dark-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
      </div>
    </main>
  );
}
