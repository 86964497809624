import numeral from "numeral";
import { useEffect } from "react";

// load a locale
numeral.register("locale", "id", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "rb",
    million: "jt",
    billion: "M",
    trillion: "T",
  },
  currency: {
    symbol: "Rp",
  },
});

// switch between locales
numeral.locale("id");

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
}

export function prettyDate(myDate, withDay) {
  var options = {};
  withDay
    ? (options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      })
    : (options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
  var dt = new Date(myDate);
  return dt.toLocaleDateString("en-US", options);
}

export function prettyTime(myDate) {
  const options = { hour: "2-digit", minute: "2-digit" };
  var dt = new Date(myDate);
  return dt.toLocaleTimeString("en-US", options);
}

export function getMonth(myDate) {
  let monthNumber = new Date(myDate).getMonth();
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthName = monthNames[monthNumber];
  if (monthName) {
    return monthName;
  }

  return "-";
}
export function getDate(myDate) {
  let dayNumber = new Date(myDate).getDate();
  if (dayNumber) {
    return dayNumber;
  }

  return "-";
}

export function isPast(myDate) {
  var dt = Date.parse(myDate);
  var curDt = Date.now();
  var isPast = false;
  dt < curDt ? (isPast = true) : (isPast = false);
  return isPast;
}

export function formatCurrency(number) {
  if (number && number > 0) {
    return numeral(parseInt(number)).format("0,0");
  }

  return "0";
}

export function grandTotal(products, shipping) {
  console.log("halo: ", products);
  let total = 0;
  if (products && products.length > 0) {
    total = products?.reduce(
      (a, b) =>
        Number(
          a + 
            (b.Product?.gross_qty > 0 && b.Product?.gross_qty <= b.qty ? 
              b.Product?.gross_price
            : b.Product?.min_price)
            * b.qty
        ),
      0
    );
  }

  if (shipping && shipping > 0) {
    total = Number(total) + Number(shipping);
  }

  return total;
}

export function totalWeight(products, shipping) {
  console.log("products: ", products);
  let total = 0;
  if (products && products.length > 0) {
    total = products.reduce(
      (a, b) =>
        Number(a) +
        Number(b.qty) *
          Number(
            b?.Product?.weight ? b.Product.weight : process.env.REACT_APP_WEIGHT
          ),
      0
    );
  }

  console.log("total weight: ", total);

  return total;
}

export function checkNull(value) {
  if (value) {
    return value;
  }
  return "";
}

export function checkStock(product) {
  let variant = product?.Product?.ProductColorSizes;
  // console.log("variant before filter:", variant);

  let filter = variant.filter(
    (i) =>
      i.product_id == product.product_id &&
      i.color_variant_id == product.color_variant_id &&
      i.size_variant_id == product.size_variant_id
  );

  // console.log("filter product: ", product.qty, product.is_po, filter[0]);
  if (filter.length > 0) {
    if (product.is_po) {
      if (Number(filter[0].stock) < Number(product.qty)) {
        return "(Pre Order)";
      } else {
        return "";
      }
    } else {
      if (Number(filter[0].stock) < Number(product.qty)) {
        return "(Out of Stock)";
      } else {
        return "";
      }
    }
  }
}

export function productPrice(product, qty) {
  var price = product.min_price;
    if (process.env.REACT_APP_SHOW_MINMAX_PRICE == 1 && product.gross_qty > 0 && product.gross_price > 0) {
      price = product.gross_price;
      return `${formatCurrency(product.gross_price)} - ${formatCurrency(
        product.min_price
      )}`;
    }
    return formatCurrency(price);
};

export function productPriceNum(product, qty) {
  var price = 0;
  price = product.min_price;
    
  if (product?.gross_qty > 0 && product?.gross_qty <= qty) {
    price = product?.gross_price;
  }
  
  return price;
};
