import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formatCurrency,
  grandTotal,
  prettyDate,
  useTitle,
} from "../helper/functions";
import { userOrders } from "../redux/actions/productsAction";
import { pageTitle } from "../helper/globals";
import { Helmet } from "react-helmet-async";

export default function OrdersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.products.user_order);
  const is_loading = useSelector((state) => state.products.loading);

  var sortedOrders = orders.sort(function (a, b) {
    return b.id - a.id;
  });
  console.log("orDers: ", sortedOrders);

  useTitle("My Orders" + pageTitle);

  useEffect(() => {
    console.log("transaction orders:");
    dispatch(userOrders());
  }, []);

  return (
    <div className="bg-light-50">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <main className="mx-auto max-w-7xl px-4 pb-24 pt-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="sr-only">Histori Transaksi</h1>

          {/* Order summary */}
          {is_loading ? (
            <div className="flex h-96 w-full animate-pulse items-center justify-center">
              <img
                className="max-w-md"
                src="/msbmotor.png"
                alt="MSB Motor loading"
              />
            </div>
          ) : (
            <div className="mt-10 lg:mt-0">
              <h2 className="text-base font-semibold text-dark-900">
              Histori Transaksi
              </h2>

              <div className="lg:grid lg:grid-cols-2 lg:gap-4 xl:gap-6">
                {sortedOrders?.length > 0 &&
                  sortedOrders.map((order) => (
                    <div
                      key={order.id}
                      className="mt-4 border border-dark-200 bg-light-50 p-2 shadow-lg"
                    >
                      <div className="flex justify-between px-2">
                        <p className="text-left text-sm font-light">
                          {prettyDate(order.createdAt)}
                          {order.is_po ? (
                            <span className="ml-2 text-sm font-light text-dark-500">
                              Pre Order 30 days
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p className="text-left text-base font-bold uppercase">
                          {order.status}
                        </p>
                      </div>
                      <ul className="divide-y divide-dark-200">
                        {order?.transaction_products?.length > 0 &&
                          order.transaction_products.map((product) => (
                            <li key={product.id} className="flex px-2 py-2">
                              <div className="flex-shrink-0">
                                <img
                                  src={`${process.env.REACT_APP_BACKEND_API}/file/${product?.product_image}`}
                                  // src="/images/pexels-quang-anh-ha-nguyen-884979.jpg"
                                  alt=""
                                  className="w-20"
                                />
                              </div>

                              <div className="ml-2 flex flex-1 flex-col">
                                <div className="flex">
                                  <div className="min-w-0 flex-1">
                                    <h4 className="text-sm">
                                      {/* <p className="mt-1 text-sm font-light uppercase text-dark-500">
                                        {product.is_po ? "Pre Order" : ""}
                                      </p> */}
                                      <p className="mt-1 text-sm font-light text-dark-500">
                                        {product.is_po ? "PO Product" : ""}
                                      </p>
                                      <a
                                        href={`/detail/${product.product_id}`}
                                        className="text-sm font-light text-dark-700 hover:text-dark-800"
                                      >
                                        {product.product_name} ( x {product.qty}{" "}
                                        )
                                      </a>
                                    </h4>
                                    <p className="mt-1 text-sm font-light text-dark-500">
                                      {product.SizeVariant?.name}
                                    </p>
                                    <p className="mt-1 text-sm font-light text-dark-500">
                                      {product.variant}
                                    </p>
                                  </div>
                                </div>

                                <div className="flex flex-1 items-end justify-between pt-2">
                                  <p className="mt-1 text-sm font-light text-dark-900">
                                    {formatCurrency(
                                      product?.discount != 0
                                        ? product.discount
                                        : product.price
                                    )}{" "}
                                    ( x {product.qty} )
                                  </p>
                                  <div className="ml-4 text-sm font-light">
                                    <p>
                                      {formatCurrency(
                                        Number(
                                          product?.discount != 0
                                            ? product.discount
                                            : product.price
                                        ) * product.qty
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                      <dl className="space-y-2 border-t border-dark-200 px-2 py-2">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm font-light">
                            Subtotal
                          </dt>
                          <dd className="text-sm font-light text-dark-900">
                            {formatCurrency(
                              grandTotal(order.transaction_products)
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-sm font-light">
                            Ongkir
                          </dt>
                          <dd className="text-sm font-light text-dark-900">
                            {formatCurrency(order?.delivery_fee)}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-dark-200 pt-2">
                          <dt className="text-sm font-light">
                            Total
                          </dt>
                          <dd className="text-base font-medium text-dark-900">
                            {formatCurrency(
                              grandTotal(
                                order.transaction_products,
                                order.delivery_fee
                              )
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-dark-200 pt-2">
                          <dt className="text-sm font-light">
                            Metode Pengiriman
                          </dt>
                          <dd className="text-base font-medium text-dark-900">
                            {order.delivery_method}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-dark-200 pt-2">
                          <dt className="text-sm font-light">
                            Nomor Resi
                          </dt>
                          <dd className="text-base font-medium text-dark-900">
                            {order.resi_number}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
