import { Helmet } from "react-helmet-async";
import { useTitle } from "../helper/functions";
import { pageTitle } from "../helper/globals";

export default function PaymentSuccess() {
  useTitle("Payment Successful" + pageTitle);

  return (
    <main className="grid min-h-full place-items-center bg-light-50 px-6 py-12 sm:py-32 lg:px-8">
      <Helmet>
        <meta
          name="description"
          content="MSB Motor description."
        />
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <div className="text-center">
        <p className="text-xs font-light uppercase text-dark-600">
          Order Diterima
        </p>
        <h1 className="mt-4 text-xl font-light tracking-tight text-dark-900 sm:text-5xl">
          Mohon segera lakukan pembayaran dan lakukan konfirmasi pembayaran
        </h1>
        <p className="mt-6 text-sm font-light leading-7 text-dark-600">
          Kami akan memproses order anda secepatnya setelah anda melakukan konfirmasi pembayaran.
        </p>
        <p className="text-sm font-light leading-7 text-dark-600">
          Kami juga telah mengirimkan info cara pembayaran ke alamat email anda.
        </p>
        <div className="border shadow mt-2 max-w-sm m-auto">
          <div className="bg-primary-600 text-white italic font-semibold px-2 py-1">BCA</div>
          <div className="text-gray-500 text-sm px-2 py-1">No. Rekening: <span className="text-gray-900 text-base font-medium">4700466669</span></div>
          <div className="text-gray-500 text-sm px-2 py-1">a/n: <span className="text-gray-900 text-base font-medium">Sonny</span></div>
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="https://wa.me/6281231520688"
            className="bg-primary-600 px-4 py-2 text-sm font-normal uppercase text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark-600"
          >
            Konfirmasi Pembayaran
          </a>
          {/* <a href="#" className="text-sm font-semibold text-dark-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
      </div>
    </main>
  );
}
